import { theme } from '@/app/common/providers/theme-provider/theme';
import styled from '@emotion/styled';
import BgBlobSvg from '../svgs/error-bg-blob.svg';
import DotsSvg from '../svgs/error-dots.svg';

const Container = styled.div`
  height: 100vh;
  min-height: 600px;
  background-color: #fdf3de;
  overflow: hidden;
  position: relative;
`;

const ContentContainer = styled.div`
  background-color: #96ce95;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 40px;

  ${theme.breakpoints.up('md')} {
    padding: 0;
    width: 43%;
  }
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  position: relative;
  z-index: 2;

  ${theme.breakpoints.up('md')} {
    left: -80px;
  }
`;

const ContentInner = styled.div`
  position: relative;
`;

const BgBlob = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 42%;
  min-height: 868px;
  height: 100vh;
  min-height: 600px;
  width: 35vh;
  min-width: 210px;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  ${theme.breakpoints.up('md')} {
    display: block;
  }
`;

const Dots = styled.div`
  position: absolute;
  z-index: 1;
  right: -20px;
  top: -10px;
  height: 0;
  padding-bottom: 81%;
  width: 62%;
  min-width: 300px;
  max-width: 490px;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }

  ${theme.breakpoints.up('md')} {
    width: 79%;
    min-width: 300px;
  }
`;

const MomenteerContainer = styled.div`
  display: none;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  width: 40%;
  z-index: 1;

  ${theme.breakpoints.up('md')} {
    display: flex;
  }
`;

const Momenteer = styled.div`
  width: 293px;
  height: 426px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-bottom: 145.3924%;
    background: url(/errors/error-momenteer.png) no-repeat;
    background-size: 100% 100%;
  }
`;

interface FullPageErrorProps {
  children: React.ReactNode | React.ReactNode[] | string;
}

export const FullPageError = ({ children }: FullPageErrorProps): React.ReactElement => {
  return (
    <Container>
      <ContentContainer>
        <Content>
          <ContentInner>{children}</ContentInner>
        </Content>
        <Dots>
          <DotsSvg />
        </Dots>
      </ContentContainer>
      <BgBlob>
        <BgBlobSvg />
      </BgBlob>
      <MomenteerContainer>
        <Momenteer />
      </MomenteerContainer>
    </Container>
  );
};
