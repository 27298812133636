import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { YellowButton } from '../../button/button';
import { FullPageError } from '../full-page-error/full-page-error';

const Title = styled(Typography)`
  line-height: 1.4;
  margin-bottom: 20px;
`;

const Content = styled(Typography)`
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 20px;
`;

export const ServerError = () => {
  return (
    <FullPageError>
      <Title variant="h1">Oops, something went wrong.</Title>
      <Content>But don’t fret&mdash; it&apos;s not your fault, let&apos;s try again!</Content>
      <YellowButton
        onClick={() => {
          window.location.reload();
          return false;
        }}
        aria-label="Refresh"
        label="Refresh"
      />
    </FullPageError>
  );
};
